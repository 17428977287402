import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RequestModalComponent } from './modal/request-modal.component';

@Component({
  selector: 'pine-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'pine';
  mobileStatus: boolean = false;
  showDropdown = false;
  selectedLanguage = 'EN';

  isScrolled = false;
  lastScrollTop = 0;

  navbarMobileActive: boolean = false;
  constructor(public Router: Router, public Dialog: MatDialog) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    const st = window.pageYOffset || document.documentElement.scrollTop;

    if (st > this.lastScrollTop) {
      // Scrolling down
      this.isScrolled = true;
    } else {
      // Scrolling up
      this.isScrolled = false;
    }

    this.lastScrollTop = st <= 0 ? 0 : st;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  closeMobileNav(event: Event): void {
    const clickedElement = event.target as HTMLElement;

    if (clickedElement.classList.contains('mobileLink')) {
      this.mobileStatus = !this.mobileStatus;
    } else {
    }
  }

  selectLanguage(language: string) {
    this.selectedLanguage = language;
    this.showDropdown = false;
  }

  onActivate() {
    window.scroll(0, 0);
  }

  requestModal(): void {
    this.Dialog.open(RequestModalComponent, {
      panelClass: 'customDialog',
      width: '824px',
      height: '746px',
      data: { RequestModal: 'PINE' },
    });
  }

  // goToLand() {
  //   if (this.mobileStatus === true) {
  //     this.mobileStatus = false;
  //   }
  // }
}
