import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

export const rotateAnim = trigger('rotateAnim', [
  transition('void => *', [style({ transform: 'rotate(0deg)' }), animate(400)]),// when the item is created
  // transition('* => void', []),   // when the item is removed
  transition('* => *', [ // when the item is changed
    style({ transform: 'rotate(-90deg)' }),
    animate(400, style({ transform: 'rotate(0deg)' })),
  ])
]);

@Component({
  selector: 'pine-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss'],
  animations: [rotateAnim]
})
export class PricesComponent implements OnInit {

  plusSign: any;
  currentSign: any;
  faq: any;
  isActiveButton = 1;

  constructor() {

    this.plusSign = [true,true,true,true]
    this.currentSign = ['../assets/icons/plus.svg','../assets/icons/plus.svg','../assets/icons/plus.svg','../assets/icons/plus.svg']

    this.faq = [
      {
        question: 'How can I get started with pine.apps and what are the subscription plans?',
        answer: 'To sign up for pine.apps, you need to submit a request by clicking on "contact us." Subscription plans can be found on the Prices page, or by emailing info@pine.com.'
      },
      {
        question: 'What kind of support and training is provided for users?',
        answer: 'To sign up for pine.apps, you need to submit a request by clicking on "contact us." Subscription plans can be found on the Prices page, or by emailing info@pine.com.'
      },
      {
        question: 'What security measures are in place to protect my data?',
        answer: 'To sign up for pine.apps, you need to submit a request by clicking on "contact us." Subscription plans can be found on the Prices page, or by emailing info@pine.com.'
      },
      {
        question: 'What does it mean to work on your cloud?',
        answer: 'To sign up for pine.apps, you need to submit a request by clicking on "contact us." Subscription plans can be found on the Prices page, or by emailing info@pine.com.'
      },

    ]

   }

  ngOnInit(): void {
  }

  setActiveButton(buttonNumber: number) {
    this.isActiveButton = buttonNumber;
  }

  changeSign(index: number){
    this.plusSign[index] = !this.plusSign[index]
    this.plusSign[index] ? this.currentSign[index] = "../assets/icons/plus.svg" : this.currentSign[index] = "../assets/icons/minus.svg";
  } 
}
