import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

const CLOSE_ICON = `
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.51001 15.4898L15.4896 6.51019" stroke="#042f42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
<path d="M15.4896 15.4898L6.51001 6.51019" stroke="#042f42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
</svg>

`;

@Component({
  selector: 'app-request-modal',
  templateUrl: './request-modal.component.html',
  styleUrls: ['./request-modal.component.scss'],
})
export class RequestModalComponent implements OnInit {
  RequestModal: any;
  applyForm = new FormGroup({
    fullName: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/),
    ]),
    phone: new FormControl(''),
    companyName: new FormControl(''),
    numberEmployees: new FormControl(null, Validators.required),
    countryId: new FormControl(null, Validators.required),
    languageId: new FormControl(null, Validators.required),
  });
  Countries: any;
  Languages: any;
  RequestSended: boolean = false;
  constructor(
    public DialogRef: MatDialogRef<RequestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.RequestModal = data.RequestModal;
    this.Countries = [
      { CountryName: 'Kazakhstan', Id: 0 },
      { CountryName: 'Russia', Id: 1 },
      { CountryName: 'England', Id: 2 },
    ];
    this.Languages = [
      { LanguageName: 'English', Id: 0 },
      { LanguageName: 'Kazakh', Id: 1 },
      { LanguageName: 'Russian', Id: 2 },
    ];
    iconRegistry.addSvgIconLiteral(
      'close',
      sanitizer.bypassSecurityTrustHtml(CLOSE_ICON)
    );
  }

  sendRequest() {
    this.RequestSended = true;
    const result = [
      this.applyForm.value.fullName ?? '',
      this.applyForm.value.email ?? '',
      this.applyForm.value.phone ?? '',
      this.applyForm.value.companyName ?? '',
      this.applyForm.value.numberEmployees ?? '',
      this.applyForm.value.countryId ?? '',
      this.applyForm.value.languageId ?? '',
    ];
    console.log('Request is submitted', result);
  }

  ngOnInit(): void {}

  onClose(): void {
    this.DialogRef.close();
  }
}
