<mat-dialog-content>
  <div class="dialog__container" *ngIf="!RequestSended">
    <div class="dialog__header">
      <div class="topDialog">
        <div class="title">Send request</div>
        <div class="customClose">
          <mat-icon
            svgIcon="close"
            aria-hidden="false"
            aria-label=""
            (click)="onClose()"
            tabindex="-1"
          ></mat-icon>
        </div>
      </div>
      <div class="description">
        Please fill out the forms below so we can contact you.
      </div>
    </div>
    <div class="dialog__form__container">
      <form [formGroup]="applyForm" (submit)="sendRequest()" class="form-group">
        <div class="formContent">
          <div class="content">
            <label for="fullName" class="form-label"
              >Full name<span class="requiredStar">*</span></label
            >
            <input
              id="fullName"
              type="text"
              formControlName="fullName"
              class="form-control custom"
              placeholder="Text here your full name"
            />
            <div
              *ngIf="
                applyForm.get('fullName')?.touched &&
                applyForm.get('fullName')?.invalid
              "
              class="alert alert-danger"
            >
              Full name is required
            </div>
          </div>
        </div>

        <div class="formContent multiple">
          <div class="content">
            <label for="email" class="form-label"
              >Email<span class="requiredStar">*</span></label
            >
            <input
              id="email"
              type="email"
              formControlName="email"
              class="form-control custom"
              placeholder="example@company.com"
            />
            <div
              *ngIf="
                applyForm.get('email')?.touched &&
                applyForm.get('email')?.hasError('required')
              "
              class="alert alert-danger"
            >
              Email is required
            </div>
            <div
              *ngIf="
                applyForm.get('email')?.touched &&
                applyForm.get('email')?.hasError('email') &&
                applyForm.get('email')?.hasError('pattern')
              "
              class="alert alert-danger"
            >
              Invalid email
            </div>
          </div>

          <div class="content">
            <label for="phone" class="form-label">Phone</label>
            <input
              id="phone"
              type="number"
              formControlName="phone"
              class="form-control custom"
              placeholder="+7 (000) 000 00 00"
            />
          </div>
        </div>

        <div class="formContent multiple">
          <div class="content">
            <label for="companyName" class="form-label">Company name</label>
            <input
              id="companyName"
              type="text"
              formControlName="companyName"
              class="form-control custom"
              placeholder="Your company name"
            />
          </div>

          <div class="content">
            <label for="numberEmployees" class="form-label"
              >Number of employees<span class="requiredStar">*</span></label
            >
            <input
              id="numberEmployees"
              type="number"
              formControlName="numberEmployees"
              class="form-control custom"
              placeholder="Choose number"
            />
            <div
              *ngIf="
                applyForm.get('numberEmployees')?.touched &&
                applyForm.get('numberEmployees')?.invalid
              "
              class="alert alert-danger"
            >
              Number of employees is required
            </div>
          </div>
        </div>

        <div class="formContent multiple">
          <div class="content">
            <label for="countryId" class="form-label"
              >Your country<span class="requiredStar">*</span></label
            >
            <select
              id="countryId"
              formControlName="countryId"
              class="form-control custom"
              placeholder="Choose country"
            >
              <option value="" disabled selected>Choose country</option>
              <option *ngFor="let ctry of Countries" [value]="ctry.Id">
                {{ ctry.CountryName }}
              </option>
            </select>
            <div
              *ngIf="
                applyForm.get('countryId')?.touched &&
                applyForm.get('countryId')?.invalid
              "
              class="alert alert-danger"
            >
              Your country is required
            </div>
          </div>

          <div class="content">
            <label for="languageId" class="form-label"
              >Language<span class="requiredStar">*</span></label
            >
            <select
              id="languageId"
              formControlName="languageId"
              class="form-control custom"
            >
              <option value="" disabled selected>Choose language</option>
              <option *ngFor="let lang of Languages" [value]="lang.Id">
                {{ lang.LanguageName }}
              </option>
            </select>
            <div
              *ngIf="
                applyForm.get('languageId')?.touched &&
                applyForm.get('languageId')?.invalid
              "
              class="alert alert-danger"
            >
              Language is required
            </div>
          </div>
        </div>

        <button class="getstarted" type="submit">Send request</button>
      </form>

      <div class="dialog__policy">
        By filling out this form, you agree to our Privacy Policy and the
        collection, storage, and processing of your personal data by our
        company.
      </div>
    </div>
  </div>

  <div class="dialog__container" *ngIf="RequestSended">
    <div class="dialog__header">
      <div class="topDialog">
        <div class="title sended">
          Success! Your request has been submitted.
        </div>
        <div class="customClose">
          <mat-icon
            svgIcon="close"
            aria-hidden="false"
            aria-label=""
            (click)="onClose()"
            tabindex="-1"
          ></mat-icon>
        </div>
      </div>
      <div class="description sended">
        Thank you for submitting your request. You will receive all the
        necessary information via email shortly. Please check your spam folder
        in case you don't see it in your inbox.
      </div>

      <div class="description">
        If you haven't received the email and it is not in your spam folder,
        please don't hesitate to contact us at
        <a href="mailto:help@pine.apps">help@pine.apps</a>.
      </div>
    </div>
  </div>
</mat-dialog-content>
