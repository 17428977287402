<!-- ======= Header ======= -->
<div id="header" class="header fixed-top" [class.scrolled]="isScrolled">
  <div class="logoPlace">
    <a class="nav-link active" class="logo"> Pine.Apps </a>
  </div>

  <div
    id="navbar"
    class="navbar"
    [ngClass]="mobileStatus ? 'navbar-mobile' : ''"
  >
    <ul>
      <li>
        <a
          class="nav-link"
          href="#"
          [ngClass]="mobileStatus ? 'mobileLink' : ''"
          (click)="closeMobileNav($event)"
          >Home</a
        >
      </li>
      <li>
        <a
          class="nav-link"
          href="#apps"
          [ngClass]="mobileStatus ? 'mobileLink' : ''"
          (click)="closeMobileNav($event)"
          >Apps</a
        >
      </li>
    </ul>
    <div class="navbarRight">
      <a href="https://dashboard.pineapps.com/get-started" target="_parent">
        <button
          class="getstarted"

          [ngClass]="mobileStatus ? 'mobileLink' : ''"
          (click)="closeMobileNav($event)"
        >
        Send request

        </button>
      </a>

<!--      <button-->
<!--        class="getstarted"-->
<!--        (click)="requestModal()"-->
<!--        [ngClass]="mobileStatus ? 'mobileLink' : ''"-->
<!--        (click)="closeMobileNav($event)"-->
<!--      >-->
<!--        Send request-->
<!--      </button>-->



      <!-- <div class="language-dropdown">
          <select [(ngModel)]="selectedLanguage">
            <option value="eng">ENG</option>
            <option value="ru">RU</option>
            <option value="kz">KZ</option>
          </select>
        </div> -->
      <!-- <div class="dropdown">
        <button class="dropdown-toggle" (click)="toggleDropdown()">
          {{ selectedLanguage }}
        </button>
        <ul class="dropdown-menu" [hidden]="!showDropdown">
          <li (click)="selectLanguage('EN')">EN</li>
          <li (click)="selectLanguage('RU')">RU</li>
        </ul>
      </div> -->
      <!-- <div class="dropdown language">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            ENG
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#">ENG</a>
            <a class="dropdown-item" href="#">RU</a>
            <a class="dropdown-item" href="#">KZ</a>
          </div>
        </div> -->
    </div>
    <span
      class="bi mobile-nav-toggle"
      (click)="mobileStatus = !mobileStatus"
      [ngClass]="mobileStatus ? 'bi-x' : 'bi-list'"
      >—</span
    >
  </div>
</div>

<!-- End Header -->

<div class="pineContent">
  <!-- <router-outlet (activate)="onActivate()"></router-outlet> -->
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="hero d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 d-flex flex-column justify-content-center">
          <div class="mainContent" data-aos="fade-up" data-aos-delay="400">
            <span class="dedicated">Pine.Apps</span> is a web-based application
            designed to help engineering teams in the industrial construction
            industry with various tasks, such as tracking man-hours, generating
            progress reports, managing the development of engineering documents,
            facilitating recruitment, and more.

            <button class="getstarted" (click)="requestModal()">
              Send request
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Hero -->

  <main id="main">
    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container" data-aos="fade-up">
        <header class="section-header"></header>

        <div class="content">
          <p>
            Initially, <span class="link">Pine.Apps</span> was developed to meet
            our internal needs and has been used for several years in our
            day-to-day operations. Our partners, including a world leader in the
            construction industry, have also relied on Pine.Apps for their
            projects. Over time, we have refined and improved the software to
            ensure that it meets the highest standards of quality and
            efficiency. Based on the positive feedback we've received from our
            clients, you can be assured that Pine.Apps is a reliable and
            effective solution for your project teams.
          </p>
        </div>
      </div>
    </section>
    <!-- End About Section -->

    <!-- ======= Section Main ======= -->
    <section id="apps" class="pricing">
      <div class="container" data-aos="fade-up">
        <header class="section-header start">
          <h2>
            <span class="link">Pine.Apps</span> for complex engineering projects
          </h2>
        </header>

        <div class="row gy-4" data-aos="fade-left">
          <div class="col-lg-12" data-aos="zoom-in" data-aos-delay="100">
            <div class="container" data-aos="fade-up">
              <div class="row gx-0">
                <div class="apps">
                  <div class="appsBox">
                    <div class="contentHeader pm">
                      <div class="icon">
                        <img src="../assets/icons/multiple-people.svg" />
                      </div>
                      <div>
                        <h2>People Management</h2>
                      </div>
                    </div>
                    <div class="contentMain">
                      <div class="textContainer">
                        <div>
                          Recruitment
                          <p>
                            Track candidates from their first contact to
                            becoming an employee.
                          </p>
                        </div>
                        <div>
                          Time Off
                          <p>
                            Track the number of vacation days that will be
                            calculated automatically.
                          </p>
                        </div>
                        <div>
                          Employees
                          <p>
                            Keep all documents in one place and track employee
                            changes.
                          </p>
                        </div>
                      </div>
                      <div class="imageContainer">
                        <img src="../assets/img/Leave-Balances2.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="apps secondBox">
                  <div class="appsBox">
                    <div class="contentHeader pc">
                      <div class="icon">
                        <img src="../assets/icons/project-control.svg" />
                      </div>
                      <div>
                        <h2>Project Control</h2>
                      </div>
                    </div>
                    <div class="contentMain">
                      <div class="textContainer">
                        <div>
                          Tasks and budget planning
                          <p>
                            Plan your project activities and calculate your
                            budget.
                          </p>
                        </div>
                        <div>
                          Personnel Lists
                          <p>
                            Assign employees to your project and choose their
                            roles.
                          </p>
                        </div>
                        <div>
                          Reports
                          <p>Export reports on the current project status.</p>
                        </div>
                      </div>
                      <div class="imageContainer">
                        <img src="../assets/img/ProjectControl.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="apps">
                  <div class="appsBox">
                    <div class="contentHeader edc">
                      <div class="icon">
                        <img src="../assets/icons/engineering.svg" />
                      </div>
                      <div>
                        <h2>Engineering Document Control</h2>
                      </div>
                    </div>
                    <div class="contentMain">
                      <div class="textContainer">
                        <div>
                          Work Breakdown Structure
                          <p>
                            Track and filter projects by their status and other
                            parameters.
                          </p>
                        </div>
                        <div>
                          Progress Steps
                          <p>
                            Assign steps within the project and monitor their
                            progress.
                          </p>
                        </div>
                        <div>
                          Collaboration
                          <p>Edit tasks and collaborate on them together.</p>
                        </div>
                      </div>
                      <div class="imageContainer">
                        <img
                          src="../assets/img/Screenshot 2023-04-18 at 11.17 1.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section Discover -->

    <section id="discover" class="discover">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>
            Discover the power of <span class="link">pine.apps</span> for your
            business
          </h2>
          <a href="https://dashboard.pineapps.com/get-started" target="_parent">
            <button class="getstarted scrollto mt-40">
              Send request
            </button>
          </a>
        </header>
      </div>
    </section>
  </main>
  <!-- End #main -->
</div>

<!-- ======= Footer ======= -->
<footer id="footer" class="footer">
  <div class="footer-content">
    <div class="container">
      <div class="footer-logo">
        <span>pine.apps</span>
      </div>

      <div class="footer-links">
        <div id="navbar" class="footer-navbar">
          <ul>
            <li><a class="nav-link" href="#">Home</a></li>
            <li><a class="nav-link" href="#apps">Apps</a></li>
          </ul>
        </div>
      </div>

      <div class="footer-info">
        <span>hello@pineapps.com</span>
      </div>
    </div>

    <div class="copyright">
      <span>Copyright © pine.apps</span>
    </div>
  </div>
</footer>
<!-- End Footer -->

<!-- <script>
  const header = document.getElementById("#hero");
  header.scrollIntoView();
</script> -->
