<div class="prices">
    <h2>Choose the <span class="secondaryColor">apps</span> to get started with</h2>
    <p>
        Select the apps, enter the number of active users (employees who will use pine.apps), and see the total cost of <span class="secondaryColor">pine.apps</span> per month. 
    </p>


    <div class="pricesBlock">
        <div><span class="secondaryColor">0-100 users:&nbsp;</span>9$ per app per user</div>
        <div><span class="secondaryColor">101-200 users:&nbsp;</span>5$ per app per user</div>
        <div><span class="secondaryColor">Corporate:&nbsp;</span>custom price.&nbsp;<span class="secondaryColor">Send a request</span></div>
    </div>





    <div class="hostBlock">
        <div class="host">
            <button type="button" class="btn priceHost" [ngClass]="{ 'active': isActiveButton === 1 }" (click)="setActiveButton(1)">Cloud (we host)</button>
            <button type="button" class="btn priceHost" [ngClass]="{ 'active': isActiveButton === 2 }" (click)="setActiveButton(2)">Self-hosted (you host)</button>
        </div>
    </div>




    <div class="pricesCardBlock">

        <div class="pricesCard1">
            <div class="mainHeaders">
                <div class="nameBlock1">App name</div>
                <div class="nameBlock2">Number of users</div>
            </div>

            <div class="priceModuleBlock">
                <div class="priceModuleLeft">
                    <div class="priceModuleCheckbox">
                        <input type="checkbox" class="form-check-input priceCheckbox" aria-label="Checkbox" checked>
                    </div>
                    <div class="priceModuleText">
                        <span class="priceModuleText1">Project Control</span> 
                        <span class="priceModuleText2"><span class="priceCost">$9 USD</span><span class="perUser"> per user per month</span></span>
                    </div>
                </div>
    
                <div class="priceModuleRight">
                    <div class="numberBlock">
                        <div class="numberSign">
                            <img src="../assets/icons/minus-gray.svg">
                        </div>
                        <div class="numberMain">
                            23
                        </div>
                        <div class="numberSign">
                            <img src="../assets/icons/plus-gray.svg">
                        </div>
                    </div>
                </div>
            </div>

            <div class="priceModuleBlock">
                <div class="priceModuleLeft">
                    <div class="priceModuleCheckbox">
                        <input type="checkbox" class="form-check-input priceCheckbox" aria-label="Checkbox" checked>
                    </div>
                    <div class="priceModuleText">
                        <span class="priceModuleText1">Timesheets</span> 
                        <span class="priceModuleText2"><span class="priceCost">$9 USD</span><span class="perUser"> per user per month</span></span>
                    </div>
                </div>
    
                <div class="priceModuleRight">
                    <div class="numberBlock">
                        <div class="numberSign">
                            <img src="../assets/icons/minus-gray.svg">
                        </div>
                        <div class="numberMain">
                            12
                        </div>
                        <div class="numberSign">
                            <img src="../assets/icons/plus-gray.svg">
                        </div>
                    </div>
                </div>
            </div>

            <div class="priceModuleBlock">
                <div class="priceModuleLeft">
                    <div class="priceModuleCheckbox">
                        <input type="checkbox" class="form-check-input priceCheckbox" aria-label="Checkbox">
                    </div>
                    <div class="priceModuleText">
                        <span class="priceModuleText1">People Recruitment</span> 
                        <span class="priceModuleText2"><span class="priceCost">$9 USD</span><span class="perUser"> per user per month</span></span>
                    </div>
                </div>
    
                <div class="priceModuleRight">
                    <div class="numberBlock">
                        <div class="numberSign">
                            <img src="../assets/icons/minus-gray.svg">
                        </div>
                        <div class="numberMain">
                            0
                        </div>
                        <div class="numberSign">
                            <img src="../assets/icons/plus-gray.svg">
                        </div>
                    </div>
                </div>
            </div>

            <div class="priceModuleBlock">
                <div class="priceModuleLeft">
                    <div class="priceModuleCheckbox">
                        <input type="checkbox" class="form-check-input priceCheckbox" aria-label="Checkbox" checked>
                    </div>
                    <div class="priceModuleText">
                        <span class="priceModuleText1">People Time Off</span> 
                        <span class="priceModuleText2"><span class="priceCost">$9 USD</span><span class="perUser"> per user per month</span></span>
                    </div>
                </div>
    
                <div class="priceModuleRight">
                    <div class="numberBlock">
                        <div class="numberSign">
                            <img src="../assets/icons/minus-gray.svg">
                        </div>
                        <div class="numberMain">
                            23
                        </div>
                        <div class="numberSign">
                            <img src="../assets/icons/plus-gray.svg">
                        </div>
                    </div>
                </div>
            </div>


            <div class="priceModuleBlock">
                <div class="priceModuleLeft">
                    <div class="priceModuleCheckbox">
                        <input type="checkbox" class="form-check-input priceCheckbox" aria-label="Checkbox">
                    </div>
                    <div class="priceModuleText">
                        <span class="priceModuleText1">Employees</span> 
                        <span class="priceModuleText2"><span class="priceCost">$9 USD</span><span class="perUser"> per user per month</span></span>
                    </div>
                </div>
    
                <div class="priceModuleRight">
                    <div class="numberBlock">
                        <div class="numberSign">
                            <img src="../assets/icons/minus-gray.svg">
                        </div>
                        <div class="numberMain">
                            0
                        </div>
                        <div class="numberSign">
                            <img src="../assets/icons/plus-gray.svg">
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="priceModuleBlock">
                <div class="priceModuleLeft">
                    <div class="priceModuleCheckbox">
                        <input type="checkbox" class="form-check-input priceCheckbox" aria-label="Checkbox">
                    </div>
                    <div class="priceModuleText">
                        <span class="priceModuleText1">Engineering Document Control</span> 
                        <span class="priceModuleText2"><span class="priceCost">$9 USD</span><span class="perUser"> per user per month</span></span>
                    </div>
                </div>
    
                <div class="priceModuleRight">
                    <div class="numberBlock">
                        <div class="numberSign">
                            <img src="../assets/icons/minus-gray.svg">
                        </div>
                        <div class="numberMain">
                            0
                        </div>
                        <div class="numberSign">
                            <img src="../assets/icons/plus-gray.svg">
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="pricesCard2">
            <div class="totalCardBlock">
                <span class="totalCardName">Project Control</span>
                <span class="totalCardUser">23 users</span>
                <span class="totalCardPrice">$184</span>
            </div>
            <div class="totalCardBlock">
                <span class="totalCardName">Timesheets</span>
                <span class="totalCardUser">12 users</span>
                <span class="totalCardPrice">$96</span>
            </div>
            <div class="totalCardBlock">
                <span class="totalCardName">Time Off</span>
                <span class="totalCardUser">23 users</span>
                <span class="totalCardPrice">$184</span>
            </div>
            <div class="totalCardBlock mt-30">
                <span class="totalCardName total">Total</span>
                <span class="totalCardPrice total">$464</span>
            </div>

            <button class="getstarted priceButton mt-30" href="#about">Start free trial</button>
            <div class="buttonInfo">
                <span>3 month trial. Credit card required.</span>
            </div>

        </div>


    </div>




    <div class="faq mt-120">
        <h2>FAQ</h2>
        <div class="faqBlock mt-40" *ngFor="let fq of faq; let i = index" (click)="changeSign(i)">
            <div class="question">{{fq.question}}</div>
            <div class="faqSign" ><img [@rotateAnim]="currentSign[i]" [src]="currentSign[i]"></div>
            <div class="answer" *ngIf="!plusSign[i]">{{fq.answer}}</div>
        </div>
    </div>

</div>
